//navbar.tsx
"use client";

import { useRouter } from "next/navigation"; // Change from next/router


import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { AvatarImage, Avatar } from "@/components/ui/avatar";
import Link from "next/link";
import { useAuth } from "../../../lib/AuthContext";
import { usePathname } from "next/navigation";
import { AnimatePresence } from "framer-motion";
import Image from "next/image";
import {
  Bell as BellIcon,
  ShoppingBag as ShoppingBagIcon,
  CheckCircleIcon,
} from "lucide-react";
import { ProfilePanel } from "./ProfilePanel";
import { useCart } from "../../../contexts/CartContext";

interface Navbar1Props {
  isSticky?: boolean;
  onProfilePanelChange?: (isOpen: boolean) => void;
  isTransparent?: boolean; // The new prop for controlling transparency
}

export function Navbar1({
  isSticky,
  onProfilePanelChange,
  isTransparent = false,
}: Navbar1Props) {
  const router = useRouter();

  const { user } = useAuth();
  const { cartItems } = useCart();
  const [isProfilePanelOpen, setIsProfilePanelOpen] = useState(false);
  const pathname = usePathname();

  const handleNavigation = (path: string) => {
    router.push(path);
  };


  const navigationItems = [
    "Marketplace",
    "Dashboard",
    "Portfolio",
    "Charts",
    "Blog",
    "About",
  ];

  useEffect(() => {
    onProfilePanelChange?.(isProfilePanelOpen);
  }, [isProfilePanelOpen, onProfilePanelChange]);

  const CartBadge = () => {
    const badgeCount = cartItems.length;

    if (badgeCount === 0) return null;

    return (
      <span
        className="absolute -top-2 -right-2 bg-blue-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center"
        key={badgeCount}
      >
        {badgeCount}
      </span>
    );
  };

  return (
    <nav className={`text-gray-200 py-3 border-b border-gray-700/50 relative shadow-sm ${isSticky ? "sticky top-0 z-20" : ""
      } ${isTransparent
        ? ""
        : "bg-slate-900/70 backdrop-blur-lg backdrop-saturate-150"
      }`}>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-14 md:h-12">
          {/* Logo - Reduced size and potentially with transparency */}
          <div className="hidden md:flex w-[180px] items-center">
            <Link href="/" className="relative group">
              <div className="absolute -inset-1.5 bg-gradient-to-r from-blue-600 to-blue-400 rounded-full blur opacity-0 group-hover:opacity-20 transition duration-200"></div>
              <Image
                src="/watchinspect.svg" // Make sure this image looks good with transparency
                width={60}
                height={60}
                alt="Watch Inspect Logo"
                className="relative transform transition-transform duration-200 group-hover:scale-110"
                style={{
                  objectFit: "contain",
                  height: "auto",
                }}
              />
            </Link>
          </div>

          {/* Desktop Navigation - Hover effect added */}
          <div className="hidden md:flex flex-1 items-center justify-center">
            <div className="flex space-x-4">
              {navigationItems.map((item) => (
                <Button
                  key={item}
                  variant="ghost"
                  onClick={() => handleNavigation(`/${item.toLowerCase()}`)}
                  className={`text-gray-200 hover:text-blue-400 hover:bg-white/10 transition-all duration-200 px-4 py-2 rounded-md relative ${pathname === `/${item.toLowerCase()}` ? "text-blue-400" : ""
                    }`}
                >
                  {item}
                  {pathname === `/${item.toLowerCase()}` && (
                    <CheckCircleIcon className="absolute -bottom-1 left-1/2 -translate-x-1/2 h-4 w-4 text-blue-400" />
                  )}
                </Button>
              ))}
            </div>
          </div>

          {/* Mobile Layout - Logo and Actions */}
          <div className="flex md:hidden items-center justify-between w-full">
            {/* Mobile Logo */}
            <Link href="/" className="relative group">
              <div className="absolute -inset-1.5 bg-gradient-to-r from-blue-600 to-blue-400 rounded-full blur opacity-0 group-hover:opacity-20 transition duration-200"></div>
              <Image
                src="/watchinspect.svg"
                width={40}
                height={40}
                alt="Watch Inspect Logo"
                className="relative transform transition-transform duration-200 group-hover:scale-110"
                style={{
                  objectFit: "contain",
                  height: "auto",
                }}
              />
            </Link>

            {/* Mobile Actions */}
            <div className="flex items-center space-x-2">
              <Button
                variant="ghost"
                className="relative group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-200 hover:bg-white/10"
              >
                <BellIcon className="h-5 w-5 text-gray-200 group-hover:text-blue-400 transition-colors" />
              </Button>

              <Link href="/checkout">
                <Button
                  variant="ghost"
                  className="relative group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-200 hover:bg-white/10"
                >
                  <ShoppingBagIcon className="h-5 w-5 text-gray-200 group-hover:text-blue-400 transition-colors" />
                  <CartBadge />
                </Button>
              </Link>

              {user ? (
                <div className="relative">
                  <Button
                    variant="ghost"
                    onClick={() => setIsProfilePanelOpen(!isProfilePanelOpen)}
                    className="group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-200 hover:bg-white/10"
                  >
                    <Avatar className="h-8 w-8 ring-2 ring-blue-500/50 ring-offset-2 ring-offset-slate-900 transition-all duration-200 group-hover:ring-blue-400">
                      <AvatarImage
                        alt="User avatar"
                        src={user?.profilePicture || "/reddit.webp"}
                        className="object-cover"
                      />
                    </Avatar>
                  </Button>
                  <AnimatePresence>
                    {isProfilePanelOpen && (
                      <div className="absolute right-0 mt-2 z-50">
                        <ProfilePanel
                          onClose={() => setIsProfilePanelOpen(false)}
                        />
                      </div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <Link href="/auth">
                  <Button
                    size="sm"
                    className="bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-200"
                  >
                    Login
                  </Button>
                </Link>
              )}
            </div>
          </div>

          {/* Desktop Right Section */}
          <div className="hidden md:flex w-[200px] items-center justify-end space-x-4">
            <Button
              variant="ghost"
              className="relative group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-200 hover:bg-white/10"
            >
              <BellIcon className="h-5 w-5 text-gray-200 group-hover:text-blue-400 transition-colors" />
            </Button>

            <Link href="/checkout">
              <Button
                variant="ghost"
                className="relative group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-200 hover:bg-white/10"
              >
                <ShoppingBagIcon className="h-5 w-5 text-gray-200 group-hover:text-blue-400 transition-colors" />
                <CartBadge />
              </Button>
            </Link>

            {user ? (
              <div className="relative">
                <Button
                  variant="ghost"
                  onClick={() => setIsProfilePanelOpen(!isProfilePanelOpen)}
                  className="group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-200 hover:bg-white/10"
                >
                  <Avatar className="ring-2 ring-blue-500/50 ring-offset-2 ring-offset-slate-900 transition-all duration-200 group-hover:ring-blue-400">
                    <AvatarImage
                      alt="User avatar"
                      src={user.profilePicture || "/reddit.webp"}
                      className="object-cover aspect-square w-full h-full"
                    />
                  </Avatar>
                </Button>
                <AnimatePresence>
                  {isProfilePanelOpen && (
                    <div className="absolute right-0 mt-2 z-50">
                      <ProfilePanel
                        onClose={() => setIsProfilePanelOpen(false)}
                      />
                    </div>
                  )}
                </AnimatePresence>
              </div>
            ) : (
              <Link href="/auth">
                <Button
                  className="bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-200"
                >
                  Login
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}